const contentful = require('contentful')

const client = contentful.createClient({
  space: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID,
  accessToken: process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN,
  environment:
    typeof process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT === 'undefined'
      ? 'master'
      : process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT,
})

export const getLinkedEntries = async (entityId) => {
  const entries = await client.getEntries({
    links_to_entry: entityId,
  })
  let dataSlug = []
  if (entries?.items?.length > 0) {
    await Promise.all(
      entries?.items?.map(async (item) => {
        if (!item?.fields?.slug) {
          const linkedDataSlug = await getLinkedEntries(item?.sys?.id)
          dataSlug = dataSlug.concat(linkedDataSlug)
        } else {
          dataSlug.push(item?.fields?.slug)
        }
      })
    )
  } else {
    const entries = await client.getEntry(entityId)
    if (entries?.fields?.slug) {
      dataSlug.push(entries?.fields?.slug)
    } else if (entries?.fields?.url) {
      dataSlug.push(entries?.fields?.url)
    }
  }
  return dataSlug
}

export const getContentfulSettings = async () => {
  try {
    const entry = await client.getEntries({
      content_type: 'settings',
      'fields.key': 'current',
      include: 10,
    })
    return entry.items.length ? entry.items[0] : null
  } catch (error) {
    console.log('error while fetching settings ', error)
  }
}
