import * as types from '@/redux/actionTypes'
// import produce from 'immer'
// import moment from 'moment'
// import jwt from 'jsonwebtoken'

const initialState = {
  isAuthenticated: null,
  // userInfo: {},
  // membership: {
  //   isActive: false,
  //   bigCommCustomerGroupID: null,
  //   customerGroup: null,
  //   community: 'Experts',
  //   category: null,
  //   discount: null,
  //   expiry_date: null,
  // },
  // access_token: '',
  expires_in: '',
  expires_at: '',
  id_token: '',
  profile: null,
  addresses: [],
  orders: [],
  wishlists: [],
  repairs: null,
  loggedOut: false,
  createAccData: null,
  promoOrderUser: null,
  customerGroup: null,
}

const ApiRecipe = (draft = initialState, action) => {
  // console.log('%c action', 'background: #222; color: #bada55')
  switch (action.type) {
    case types.AUTH_TOKEN_SUCCESS:
      return {
        ...draft,
        isAuthenticated: true,
        loggedOut: false,
      }
    case types.LOAD_PROFILE_SUCCESS:
      return {
        ...draft,
        profile: action.payload,
      }
    case types.LOAD_ADDRESSES_SUCCESS:
      return {
        ...draft,
        addresses: action.payload,
      }
    case types.LOAD_PROFILE_ORDERS_SUCCESS:
      return {
        ...draft,
        orders: action.orders,
      }
    case types.AUTH_TOKEN_FAILURE:
      return {
        ...draft,
        isAuthenticated: false,
      }
    case types.AUTH_SIGNOUT_REQUEST:
      return {
        ...initialState,
        loggedOut: true,
      }
    case types.UPDATE_PROFILE_SUCCESS:
      return {
        ...draft,
        profile: action.payload,
      }
    case types.UPDATE_PROFILE_ADDRESS_SUCCESS:
      return {
        ...draft,
        addresses: action.payload,
      }
    case types.ADD_PROFILE_ADDRESS_SUCCESS:
      return {
        ...draft,
        addresses: action.payload,
      }
    case types.LOAD_WISHLIST_SUCCESS:
      return {
        ...draft,
        wishlists: action.payload,
      }
    case types.LOAD_REPAIRS_SUCCESS:
      return {
        ...draft,
        repairs: action.payload,
      }
    case types.CREATE_ACC_DATA_SUCCESS:
      return {
        ...draft,
        createAccData: action.payload,
      }
    case types.PROMO_ORDER_USER_SUCCESS:
      return {
        ...draft,
        promoOrderUser: action.payload,
      }
    case types.LOAD_CUSTOMER_GROUP_SUCCESS:
      return {
        ...draft,
        customerGroup: action.payload,
      }
    case types.CLEAR_CUSTOMER_DATA_SUCCESS:
      return initialState
    // case types.AUTH_SIGNOUT_SUCCESS:
    //   draft = initialState
    //   return draft
    // case types.PROPROGRAM_CODE_VERIFY_SUCCESS:
    //   const { customerGroup, message, membership } = action.payload
    //   let verifyMembership = {}
    //   let verifyMembershipGroupId = customerGroup.id
    //   if (membership && customerGroup) {
    //     verifyMembership = membership
    //     verifyMembership.customerGroup = customerGroup
    //     verifyMembership.isActive = true
    //     verifyMembership.bigCommCustomerGroupID = membership.customer_group_id
    //     verifyMembershipGroupId = membership.customer_group_id
    //   } else {
    //     verifyMembership = initialState.membership
    //   }
    //   return {
    //     ...draft,
    //     membership: verifyMembership,
    //     userInfo: {
    //       ...draft.userInfo,
    //       bigCommCustomerGroupID: verifyMembershipGroupId,
    //     },
    //   }
    // case types.PROPROGRAM_MEMBERSHIP_SUCCESS:
    //   const { customerGroup: currentCustomerGroup, membership: currentMembership } = action.payload
    //   let newMembership = {}
    //   let membershipGroupId = null
    //   if (currentMembership && currentCustomerGroup) {
    //     newMembership = currentMembership
    //     newMembership.customerGroup = currentCustomerGroup
    //     newMembership.isActive = true
    //     newMembership.bigCommCustomerGroupID = currentMembership.customer_group_id
    //     membershipGroupId = currentMembership.customer_group_id
    //   } else {
    //     newMembership = initialState.membership
    //   }
    //   return {
    //     ...draft,
    //     membership: newMembership,
    //     userInfo: {
    //       ...draft.userInfo,
    //       bigCommCustomerGroupID: membershipGroupId,
    //     },
    //   }
    default:
      return draft
  }
}

export const userReducer = ApiRecipe
