import { combineReducers } from 'redux'
import { uiReducer } from './uiReducer'
import { userReducer } from './userReducer'
import { cartReducer } from './cartReducer'
import { repairReducer } from './repairReducer'
import { compareReducer } from './compareReducer'
import { navReducer } from '@/redux/reducers/navReducer'

const rootReducer = combineReducers({
  uiReducer: uiReducer,
  userReducer: userReducer,
  cartReducer: cartReducer,
  repairReducer: repairReducer,
  compareReducer: compareReducer,
  navReducer: navReducer,
})

export default rootReducer
