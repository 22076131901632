import * as types from '@/redux/actionTypes'
import produce from 'immer'
import {UPDATE_REPAIR_PRODUCT_SUCCESS} from "@/redux/actionTypes";

const initialState = {
  comparedProducts: [],
  compareAvailable: false
}

const ApiRecipe = (draft = initialState, action) => {
  switch (action.type) {
    case 'ADD_COMPARE_PRODUCT':
      return {
        ...draft,
        comparedProducts: [
          ...draft.comparedProducts,
          action.payload,
        ],
        // compareAvailable: draft.comparedProducts.length > 1 && draft.comparedProducts.length < 5,
      }
    case 'REMOVE_COMPARE_PRODUCT':
      return {
        ...draft,
        comparedProducts: draft.comparedProducts.filter(item => action.payload.uid !== item.uid),
        // compareAvailable: draft.comparedProducts.length > 1,
        // compareBoxAction: 'remove'
      }
    case 'COMPARE_AVAILABLE':
      return {
        ...draft,
        compareAvailable: action.payload,
      }
    case 'CLEAR_COMPARE':
      return initialState
    // case 'CLEAR_COMPARED_PRODUCTS':
    //   return {
    //     ...draft,
    //     comparedProducts: [],
    //     compareBoxAction: null
    //   }
    // case 'SET_COMPARED_PRODUCTS':
    //   return {
    //     ...draft,
    //     comparedProducts: action.payload.comparedProducts,
    //     compareBoxAction: action.payload.compareBoxAction
    //   }
    // case 'UPDATE_ADDING_COMPARE_PRODUCT': {
    //   return {
    //     ...draft,
    //     addingCompareProduct: action.payload
    //   }
    // }
    // case 'UPDATE_PRODUCT_DATA': {
    //   return {
    //     ...draft,
    //     product: action.payload
    //   }
    // }
      default:
        return draft
  }
}

export const compareReducer = produce(ApiRecipe)
