import * as types from '../actionTypes'
import produce from 'immer'

const initialState = {
  cartModal: false,
  searchModal: false,
  loginModal: false,
  createModal: false,
  resetModal: false,
  redirectToMainPage: false,
  sizeChartModal: {
    productId: null,
    data: null,
  },
}

const ApiRecipe = (draft = initialState, action) => {
  // console.log('payload', action)
  switch (action.type) {
    case types.UPDATE_CART_MODAL_SUCCESS:
      return {
        ...draft,
        cartModal: action.payload, //cartModal: !initialState.cartModal,
      }
    case types.UPDATE_LOGIN_MODAL_SUCCESS:
      return {
        ...draft,
        loginModal: action.payload,
      }
    case types.UPDATE_SIGNUP_MODAL_SUCCESS:
      return {
        ...draft,
        createModal: action.payload,
      }
    case types.UPDATE_RESET_MODAL_SUCCESS:
      return {
        ...draft,
        resetModal: action.payload,
        loginModal: true,
      }
    case types.REDIRECT_TO_MAIN_PAGE_SUCCESS:
      return {
        ...draft,
        redirectToMainPage: action.payload,
      }
    case types.LOAD_SIZE_CHART_SUCCESS:
      return {
        ...draft,
        sizeChartModal: action.payload,
      }
    default:
      return draft
  }
}

export const uiReducer = produce(ApiRecipe)
