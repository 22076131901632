import * as types from '@/redux/actionTypes'

const initialState = {}

const NavApi = (draft = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_NAV_ADDITIONAL_CONTENT:
      return {
        ...draft,
        ...action.payload,
      }
    default:
      return draft
  }
}

export const navReducer = NavApi
