import * as types from '@/redux/actionTypes'
import produce from 'immer'
import { UPDATE_REPAIR_PRODUCT_SUCCESS } from '@/redux/actionTypes'

const initialState = {
  navigation: {
    product: {
      title: 'Product',
      subtitle: 'Tell us about your Product',
      pageNum: '01',
      pageURL: '/warranties/repair/order-history',
      step: 1,
      isActive: true,
      isCompleted: false,
      isDisabled: false,
    },
    damage: {
      title: 'Damage',
      subtitle: 'Detail the Damage',
      pageNum: '02',
      pageURL: '/warranties/repair/damage',
      step: 2,
      isActive: false,
      isCompleted: false,
      isDisabled: true,
    },
    summary: {
      title: 'Summary',
      subtitle: 'Review your Repair',
      pageNum: '03',
      pageURL: '/',
      step: 3,
      isActive: false,
      isCompleted: false,
      isDisabled: true,
    },
  },
  repairProduct: {},
  draftRepair: null,
  yourDetailForWR: false,
}

const ApiRecipe = (draft = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_NAVIGATION_SUCCESS:
      return {
        ...draft,
        navigation: action.payload,
      }
    case types.UPDATE_REPAIR_PRODUCT_SUCCESS:
      return {
        ...draft,
        repairProduct: action.payload,
      }
    case types.UPDATE_DRAFT_REPAIR_SUCCESS:
      return {
        ...draft,
        draftRepair: action.payload,
      }
    case types.CLEAR_DRAFT_REPAIR_SUCCESS:
      return initialState
    case types.WR_YOUR_DETAIL_CHANGE_SUCCESS:
      return {
        ...draft,
        yourDetailForWR: action.payload,
      }
    default:
      return draft
  }
}

export const repairReducer = produce(ApiRecipe)
